<template>
  <span>
    <k-button
      :disabled="cikkId == 0"
      :id="buttonId"
      variant="secondary"
      icon="fa-share"
      @click="CikkMegosztas"
      :loading="loading"
    >
    </k-button>
    <b-popover
      placement="top"
      boundary="window"
      :target="buttonId"
      :show.sync="popoverVisible"
      triggers="click"
    >
      <template #title>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            Megosztás
          </div>
          <div @click="popoverVisible = false" class="ml-2 pointer">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </template>
      <div>
        Az alábbi linken a cikk elérhető. A link automatikusan
        vágólapra lett helyezve.
      </div>
      <div class="mt-2">
        <a :href="link" target="_blank">
          {{ link }}
        </a>
      </div>
    </b-popover>
    <b-tooltip
      :target="buttonId"
      triggers="hover"
      noninteractive
      v-if="!popoverVisible"
    >
      Megosztás
    </b-tooltip>
  </span>
</template>

<script>
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { hirService } from '../../services/HirService';
import { saveToClipboard } from '../../utils/common';
let ct = 0;
export default {
  name: 'cikk-megosztas',
  data() {
    return {
      ct: ct++,
      link: '',
      loading: false,
      popoverVisible: false,
      mounted: false,
    };
  },
  mounted() {},
  created() {},
  methods: {
    async CikkMegosztas() {
      this.popoverVisible = false;
      this.link = '';
      this.loading = true;
      try {
        let result = await hirService.CikkMegosztas(this.cikkId);
        this.link = result;
        this.popoverVisible = true;
        saveToClipboard(result);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.loading = false;
    },
  },
  computed: {
    buttonId() {
      return 'megosztas-button-' + ct;
    },
  },
  watch: {},
  props: {
    cikkId: {
      required: true,
    },
  },
};
</script>
